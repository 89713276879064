import React, {Fragment} from "react";
import Grid from "@mui/material/Grid";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import ShowAllFieldsToggle from "./ShowAllFieldsToggle";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../../model/propertyTypes";
import {Field, PhysicalPropertySubType, PhysicalPropertyType} from "../../model/fields";

const SalesPanel = ({comp, highlight, showAllFields, ...props}) => {
    const fieldProps = {comp, highlight};

    const [showAll, setShowAll] = React.useState(showAllFields || false);
    const toggleShowAll = (evt) => {
        setShowAll(evt.target.checked);
    };
    const isCurrentListing = Field.SALES_STATUS.get(comp) === "Current Listing";
    const isDevelopmentLands = PhysicalPropertyType.isResidentialDevLands(comp);
    const isMobileHomePark = PhysicalPropertySubType.isMobileHomePark(comp);
    const isMultiFamily = Field.PHYSICAL_DEVELOPMENT_TYPE.get(comp) !== "Single Family" && !!Field.PHYSICAL_DEVELOPMENT_TYPE.get(comp);
    const isServiceStation = PhysicalPropertySubType.isServiceStation(comp);
    const isCarWash = PhysicalPropertySubType.isCarWash(comp);
    const isHotelOrMotel = PhysicalPropertySubType.isHotelOrMotel(comp);
    const isGolfCourse = PhysicalPropertySubType.isGolfCourse(comp);

    return (
        <CompEditorPanel name="SalesPanel" comp={comp} {...props}
             extra={<ShowAllFieldsToggle value={showAll} onChange={toggleShowAll}/>}>
            <FormRow>
                <Grid item md={4} >
                    <CompField field={Field.SALES_STATUS} {...fieldProps}/>
                </Grid>
                <Grid item md={4}>
                    <CompField field={Field.SALES_DATE} {...fieldProps} disabled={isCurrentListing}/>
                </Grid>
                <Grid item md={4}>
                    <CompField field={Field.SALES_DAYS_ON_MARKET} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={4} >
                    <CompField field={Field.SALES_CLOSING_DATE} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_CONDITIONS} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PROPERTY_RIGHTS} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE} {...fieldProps}/>
                </Grid>
                {(comp.type === ICI || comp.type === MULTI_FAMILY) &&
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_SQFT} {...fieldProps}/>
                </Grid>}
                {showAll && comp.type === VACANT_LAND && isDevelopmentLands &&
                <Grid item md={6}>
                    <CompField field={Field.SALES_DEVELOPMENT_COST_CHARGES} {...fieldProps}/>
                </Grid>
                }
            </FormRow>
            {isServiceStation && <>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_FUEL_SALES} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_LITRE} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_FUEL_MARGIN_PER_LITRE} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_CROSS_LEASE_PER_LITRE} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_COMBINED_FUEL_MARGIN} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_SERVICE_STATION_BRAND} {...fieldProps}/>
                </Grid>
            </FormRow>
            </>}
            {isServiceStation && <>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_CONVENIENCE_STORE_SALES} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_CONVENIENCE_STORE_MARGIN} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_CAR_WASH_SALES} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_CAR_WASH_MARGIN} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_FAST_FOOD_SALES} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_FAST_FOOD_MARGIN} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_OTHER_SALES} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_OTHER_MARGIN} {...fieldProps}/>
                    </Grid>
                </FormRow>
            </>}
            {isCarWash && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_BAY} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {isHotelOrMotel && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_ROOM} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {isMobileHomePark && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_PAD} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {PhysicalPropertySubType.isMarineFacilities(comp) &&
              <FormRow>
                  <Grid item md={6}>
                      <CompField field={Field.SALES_PRICE_PER_MOORAGE} {...fieldProps}/>
                  </Grid>
              </FormRow>
            }
            {isGolfCourse && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_HOLE} {...fieldProps}/>
                </Grid>
            </FormRow>
            }
            {showAll && (comp.type === ICI || comp.type === MULTI_FAMILY) &&
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_SQFT_WITHOUT_BASEMENT} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_SQFT_WITH_BASEMENT} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {comp.type !== RESIDENTIAL_RENT && comp.type !== LEASE && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_MAIN_FLOOR_PLATE_AREA} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {showAll && <Fragment>
            {comp.type === VACANT_LAND ?
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_DEMOLITION_COSTS} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_CONTRIBUTORY_IMPROVEMENTS} {...fieldProps}/>
                </Grid>
            </FormRow> :
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_ADJUSTMENT} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_EXPENDITURES_POST_SALE} {...fieldProps}/>
                </Grid>
            </FormRow>
            }
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_EFFECTIVE_PRICE} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_USE_EFFECTIVE_SALE_PRICE} {...fieldProps}/>
                </Grid>
            </FormRow>
            </Fragment>}
            {(comp.type === MULTI_FAMILY || comp.type === ICI) &&
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_UNIT} {...fieldProps}/>
                </Grid>
            </FormRow>
            }
            {comp.type === MULTI_FAMILY && showAll && <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_ROOM} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_PRICE_PER_BEDROOM} {...fieldProps}/>
                </Grid>
            </FormRow>}
            {comp.type === VACANT_LAND &&
            <Fragment>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_SF_TOTAL} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_SF_EFFECTIVE} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_ACRE_TOTAL} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_ACRE_EFFECTIVE} {...fieldProps}/>
                    </Grid>
                </FormRow>
                {showAll && <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_EFFECTIVE_PRICE_PER_ACRE_TOTAL} {...fieldProps}/>
                    </Grid>
                </FormRow>}
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_SF_BUILDING_AREA} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_FRONTAGE} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_ARABLE_AREA} {...fieldProps}/>
                    </Grid>
                    {showAll && <Grid item md={6}>
                        <CompField field={Field.SALES_PRICE_PER_PROPOSED_GROSS_FLOOR_AREA} {...fieldProps}/>
                    </Grid>}
                </FormRow>
                {showAll && <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_EFFECTIVE_PRICE_PER_ARABLE_AREA} {...fieldProps}/>
                    </Grid>
                </FormRow>}
                {isDevelopmentLands &&
                <FormRow>
                  <Grid item md={6}>
                      <CompField field={Field.SALES_PRICE_PER_DEVELOPABLE} {...fieldProps}/>
                  </Grid>
                </FormRow>}
                {isDevelopmentLands && isMultiFamily &&
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_DENSITY_PER_ACRE_TOTAL} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.SALES_DENSITY_PER_ACRE_EFFECTIVE} {...fieldProps}/>
                    </Grid>
                </FormRow>}
            </Fragment>
            }
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.SALES_SELLER} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.SALES_BUYER} {...fieldProps}/>
                </Grid>
            </FormRow>
            {showAll &&
            <Fragment>
                <FormRow>
                    <Grid item md={12}>
                        <CompField field={Field.SALES_FINANCING_DESCRIPTION} {...fieldProps}/>
                    </Grid>
                </FormRow>

            </Fragment>
            }
        </CompEditorPanel>
    )
};

export default SalesPanel;
